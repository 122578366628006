import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import '../css/login.css';

const Login = () => {
    let navigate = useNavigate();

  const [credentials, setCredentials] = useState({ pass: "" });

  const [cookies, setCookie] = useCookies(["nhAdminToken"]);

  const onChangeInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const errorCreds = ({ closeToast }) => (
    <div>
      <p className="cgReg" style={{ textAlign: "center", color: "red" }}>
        Wrong Credentials
      </p>
    </div>
  );

  const successCreds = ({ closeToast }) => (
    <div>
      <p className="cgReg" style={{ textAlign: "center", color: "green" }}>
        Logged in successfully
      </p>
    </div>
  );

  const errorCredsAlert = () => toast(errorCreds);
  const successCredsAlert = () => toast(successCreds);

  const handleLogin = async (e) => {
    e.preventDefault();
    const { pass } = credentials;
    const response = await fetch("https://api.nurulhasan.info/api/others/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ pass }),
    });

    const json = await response.json();
    if (json.success) {
      setCredentials({ pass: "" });
      successCredsAlert();
      setCookie("nhAdminToken", json.token, { maxAge: "86400" });
      navigate('/dashboard')
    } else if (!json.success) {
      errorCredsAlert();
    }
  };

  const handleCookie = () => {
    if(!cookies.nhAdminToken){
        alert("no cookied available with this name");
    }else{
        alert(cookies.nhAdminToken)
    }
  };

  useEffect(() => {
    if(cookies.nhAdminToken){
        navigate('/dashboard');
    }
  })
  return (
    <div>
      <ToastContainer draggablePercent={60} position="top-center" />
      <h1>Login Page</h1>
      <p>{credentials.pass}</p>
      <form onSubmit={handleLogin}>
        <input type="text" id="pass" name="pass" value={credentials.pass} onChange={onChangeInput} autoComplete="off" />
        <button type="submit">Log in</button>
      </form>
      <button type="button" onClick={handleCookie}>
        Check Cookie
      </button>
    </div>
  );
};

export default Login;
