import React, { useEffect, useState } from "react";
import TimeAgo from 'react-timeago';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  let navigate = useNavigate();

  const [cookies, removeCookie] = useCookies(["nhAdminToken"]);

  const [userMsgs, setUserMsgs] = useState([]);
  const [users, setUsers] = useState([]);

  const handleCookie = () => {
    if (!cookies.nhAdminToken) {
      alert("no cookied available with this name");
    } else {
      alert(cookies.nhAdminToken);
    }
  };

  const fetchUserMsgs = async () => {
    const response = await fetch("https://api.nurulhasan.info/api/users/fetchusermsgs", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": cookies.nhAdminToken,
      },
    });

    const json = await response.json();
    if (json.error) {
      alert(json.errorMsg);
    } else if (json.success) {
      setUserMsgs(json.userMsgs);
      setUsers(json.users);
    }
  };

  const logout = () => {
    removeCookie("nhAdminToken", "");
    navigate("/");
  };

  useEffect(() => {
    if (!cookies.nhAdminToken) {
      navigate("/");
    }
     else {
      fetchUserMsgs();
    }
  });

  return (
    <div>
      <p>Dashboard</p>
      <button type="button" onClick={handleCookie}>
        Check Cookie
      </button>
      <br />
      <button type="button" onClick={logout}>
        Log out
      </button>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Related Service</th>
            <th>Description</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
         {userMsgs.map((userMsg) => {
            const user = users.find((user) => user.userId === userMsg.userId);
            return <tr key={userMsg.msgId}>
                <td>{user.fname+" "+user.lname}</td>
                <td>{user.email}</td>
                <td>{userMsg.relatedservice}</td>
                <td>{userMsg.description}</td>
                <td>{<TimeAgo date={userMsg.created_at}/>}</td>
            </tr>
         })}
        </tbody>
      </table>
    </div>
  );
};

export default Dashboard;
